@tailwind base;
@tailwind components;
@tailwind utilities;

.text-xs {
    font-size: clamp(0.79rem, -0.01vw + 0.79rem, 0.78rem);
    line-height: 1.7;
}

.text-sm {
    font-size: clamp(0.89rem, 0.08vw + 0.87rem, 0.94rem);
    line-height: 1.6;
}

body, .text-base {
    font-size: clamp(1rem, 0.21vw + 0.96rem, 1.13rem);
    line-height: 1.5;
}

.text-lg {
    font-size: clamp(1.13rem, 0.37vw + 1.05rem, 1.35rem);
    line-height: 1.5;
}

.text-xl {
    font-size: clamp(1.27rem, 0.59vw + 1.15rem, 1.62rem);
    line-height: 1.4;
}

.text-2xl {
    font-size: clamp(1.42rem, 0.87vw + 1.25rem, 1.94rem);
    line-height: 1.4;
}

.text-3xl {
    font-size: clamp(1.6rem, 1.22vw + 1.36rem, 2.33rem);
    line-height: 1.3;
}

.text-4xl {
    font-size: clamp(1.8rem, 1.66vw + 1.47rem, 2.8rem);
    line-height: 1.3;
}
.btn {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    text-transform: uppercase;
    font-weight: 600;
}

span#footerLogo {
    transition-duration: 1500ms;
    transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    display: inline-block;
}
.container {
    @apply px-4 lg:px-12
}
.btn-medium {
    @apply w-40 text-center
}

.btn-primary {
    @apply bg-purple text-white hover:bg-purple/70
}

.btn-secondary {
    @apply bg-white text-black hover:bg-white/70
}

.main {
    @apply container py-20
}

*:hover {
    transition: all 0.3s;
}

#hero {
    background-image: url(./assets/hero.png);
    background-size: 75%;
    background-repeat: no-repeat;
    background-position: bottom right;
}

::-webkit-scrollbar {
    background-color: #030303;
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-corner {
    background-color: #030303;
}

::-webkit-scrollbar-thumb {
    border-radius: 0.8rem;
    background-color: #3500D3;
}

::-webkit-scrollbar-track {
    border-radius: 0.8rem;
}
.section-first {
    height: calc(100vh - 7rem);
}

.section-last {
    height: calc(100vh - 7rem);
    padding-bottom: 0!important;
} 

html {
    scroll-snap-type: y proximity;
    scroll-padding-top: 7rem;
    scroll-behavior: smooth; 
}

section {
    scroll-snap-align: start;
    scroll-snap-stop: always;
}

.max-w-fill {
    max-width: -webkit-fill-available;
}

.bg2 {
    position: relative;
}

.bg2:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.45;
    background-image: url(./assets/bg2.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
